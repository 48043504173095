<template>
  <div>
    <section class="white">
      <v-container>
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <h2
              :class="[$vuetify.breakpoint.mdAndUp ? 'display-3' : 'headline']"
              class="mb-4"
            >
              Δίπλωμα Φορτηγού Κατηγορία Γ
            </h2>

            <p class="subtitle-1">
              Φορτηγά με δυνατότητα έλξης έως 750 kg. Απαραίτητη προϋπόθεση ο υποψήφιος να είναι κάτοχος Β κατηγορίας τουλάχιστον. Ισχύει για 5 έτη από την ημερομηνία έκδοσης ή από προηγούμενη ανανέωση.
            </p>
            <br>
            <p class="display-1">
              ΠΡΟΫΠΟΘΕΣΕΙΣ
            </p><p class="subtitle-1">
              <ul>
                <li>Να έχει την κανονική του διαμονή στην Ελλάδα.</li>
                <li>Να είναι Κάτοχος ισχύουσας άδειας οδήγησης κατηγορίας Β τουλάχιστον.</li>
                <li>Ελάχιστες προϋποθέσεις σωματικής και διανοητικής ικανότητας που ισχύουν για την κατηγορία Γ.</li>
                <li>Επιτυχία σε θεωρητική και πρακτική εξέταση, μετά από θεωρητική και πρακτική εκπαίδευση.</li>
                <li>Να μην κατέχει άλλη άδεια οδήγησης, ίδιας κατηγορίας, ελληνική ή κράτους-μέλους της Ε.Ε.(συμπεριλαμβανομένων των κρατών Νορβηγίας, Ισλανδίας και Λιχτενστάιν).</li><br>
              </ul>
            </p>
            <v-btn
              color="success"
              class="px-5"
              large
              :to="`/contact-me`"
            >
              Επικοινωνηστε τωρα
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            md="5"
            offset-md="1"
          >
            <v-card>
              <v-img src="/static/truck.png" />
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <section class="grey lighten-3 pb-10 pt-10">
      <v-container>
        <v-row>
          <v-col
            v-for="([icon, number, name, desc, desc2], i) in experiences"
            :key="i"
            cols="12"
            md="6"
          >
            <div class="text-center">
              <div
                class="text-uppercase headline font-weight-bold mb-2"
                v-text="name"
              />
              <v-icon
                size="84"
                class="mb-3"
                v-text="icon"
              />
              <div
                class="display-2 font-weight-bold mb-2"
                v-text="number"
              />
              <div
                class="font-weight-bold"
                v-text="desc"
              />
              <div
                class="font-weight-bold"
                v-text="desc2"
              />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <section class="white pb-0">
      <v-container>
        <h2
          :class="[$vuetify.breakpoint.mdAndUp ? 'display-1' : 'headline']"
          class="mb-4"
        >
          Απαιτούμενα δικαιολογητικά
        </h2>
        <v-row>
          <v-col
            v-for="(highlight, i) in highlights"
            :key="i"
            cols="12"
            md="6"
          >
            <v-card-title v-text="highlight" />
            <v-text
              v-if="highlight=='Αν ο υποψήφιος ΔΕΝ έχει και άλλο επαγγελματικό δίπλωμα'"
            >
              <ul>
                <li>Ταυτότητα ή ελληνικό διαβατήριο σε ισχύ. (Για όσους δεν έχουν ελληνική υπηκοότητα χρειάζεται άδεια διαμονής που να εμφανίζονται 186 ημέρες συνεχής διαμονής στην Ελλάδα και διαβατήριο</li>
                <li>ΑΦΜ (αν δεν υπάρχει πρέπει να εκδοθεί από την εφορία.</li>
                <li>Φωτοτυπία διπλώματος</li>
                <li>Τέσσερις (4) έγχρωμες φωτογραφίες διαβατηρίου</li>
                <li>Παράβολο 60€ (κωδ:20), 30€ (κωδ:28) για την έκδοση του διπλώματος, παράβολα 15€ (κωδ:2037) για γραπτά και 15€ (κωδ:2038) για πορεία,παραβολα 20€ (κωδ:26) και 20€ (κωδ:27) παράβολo παραλαβής 108,15€ (κωδ:64)</li>
                <li>Έκδοση των πιστοποιητικών υγείας από συμβεβλημένους με το Υ.Μ.Ε. (Παθολόγο και Οφθαλμίατρο) μαζί με την απόδειξη των γιατρών</li>
                <li>Βιβλιάριο Ασθενείας (αν δεν υπάρχει χρειάζονται οι εξής εξετάσεις: Γενική αίματος, ουρία, ακτινογραφία θώρακος, καρδιογράφημα)</li>
                <li>Υπεύθυνη δήλωση και αίτηση εκτύπωσης (τις προμηθεύεστε από τη σχολή)</li>
              </ul>
            </v-text>
            <v-text v-if="highlight=='Αν ο υποψήφιος ΕΧΕΙ και άλλο επαγγελματικό δίπλωμα'">
              <ul>
                <li>Ταυτότητα ή ελληνικό διαβατήριο σε ισχύ. (Για όσους δεν έχουν ελληνική υπηκοότητα χρειάζεται άδεια διαμονής που να εμφανίζονται 186 ημέρες συνεχής διαμονής στην Ελλάδα και διαβατήριο</li>
                <li>ΑΦΜ (αν δεν υπάρχει πρέπει να εκδοθεί από την εφορία.</li>
                <li>Φωτοτυπία διπλώματος</li>
                <li>Τέσσερις (4) έγχρωμες φωτογραφίες διαβατηρίου</li>
                <li>Παράβολο 30€ (κωδ:28) για την έκδοση του διπλώματος, παράβολα 15€ (κωδ:2037) για γραπτά και 15€ (κωδ:2038) για πορεία, παράβολα 20€ (κωδ:26) και 20€ (κωδ:27) και παράβολο παραλαβής 108,15€(κωδ:64)</li>
                <li>Υπεύθυνη δήλωση και αίτηση εκτύπωσης (τις προμηθεύεστε από τη σχολή ή τις κατεβάζετε από το site)</li>
              </ul>
            </v-text>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <section class="white pt-0">
      <v-container>
        <div class="text-center font-weight-bold">
          <v-text>
            Τα παράβολα εκδίδονται απο την εφορία ή ηλεκτρονικά απο το <a href="https://www1.gsis.gr/sgsisapps/eparavolo/public/welcome.htm?fbclid=IwAR3fE4tXJ-I1NlFsMfud2NLlWjn13VrFOmBm7AICQlCda0kdDPQt2XN73ww">e-paravolo</a> και τα αναλαμβάνει και η σχολή να τα εκδώσει χωρίς επιβάρυνση!
          </v-text>
        </div>
      </v-container>
    </section>

    <section class="grey lighten-3">
      <v-container>
        <v-row>
          <v-col
            v-for="([name, path], i) in pdfs"
            :key="i"
            :path="path"
            cols="6"
            md="6"
          >
            <div class="text-center">
              <div
                class="headline font-weight-bold mb-3"
                v-text="name"
              />
              <v-btn
                color="success"
                class="px-5"
                large
                @click="readFile(path)"
              >
                ΛΗΨΗ
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
  export default {
    data: () => ({
      highlights: [
        'Αν ο υποψήφιος ΔΕΝ έχει και άλλο επαγγελματικό δίπλωμα',
        'Αν ο υποψήφιος ΕΧΕΙ και άλλο επαγγελματικό δίπλωμα'
      ],
      experiences: [
        ['mdi-steering', '15 ώρες', 'Πρακτικα μαθηματα'],
        ['mdi-book-open-variant', '15 ώρες', 'θεωρητικα μαθηματα']
      ],
      pdfs: [
        ['Εκτύπωση Αίτησης', 'static/εκτύπωση-αίτησης.pdf'],
        ['Επέκταση Ισχύουσας Άδειας Οδήγησης', 'static/epektasi-isxioysas-adeias-TA05.pdf'],
        ['Αίτηση Χορήγησης ΠΕΙ', 'static/Αίτηση-Χορήγησης-ΠΕΙ-Αρχικής-Επιμόρφωσης-Μ-ΤΑΟ06.pdf'],
        ['e-Παράβολο', 'https://www1.gsis.gr/sgsisapps/eparavolo/public/welcome.htm']
      ]
    }),

    methods: {
      readFile (path) {
        window.open(path, '_blank')
      }
    }
  }
</script>
